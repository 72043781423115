import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import { green, yellow, red } from '@material-ui/core/colors';
import {
  CardHeader,
  Checkbox,
  FormControlLabel,
  Paper
} from '@material-ui/core';
import { Lesson } from '../types';
const size = 350; // The size square in px
const useStyles = makeStyles({
  cardContainer: {},
  root: {},
  bullet: {},
  title: {},
  pos: {},
  beginner: {
    backgroundColor: green[500]
  },
  intermediate1: {
    backgroundColor: yellow[400]
  },
  intermediate2: {
    backgroundColor: yellow[800]
  },
  advanced1: {
    backgroundColor: red[400]
  },
  advanced2: {
    backgroundColor: red[400]
  }
});

interface IProps {
  lesson: Lesson;
}
export const LaunchPad = ({ lesson }: IProps) => {
  const classes = useStyles();
  const classList = [
    classes.beginner,
    classes.intermediate1,
    classes.intermediate2,
    classes.advanced1,
    classes.advanced2
  ];
  const backgroundColor = classList[lesson.level];
  const finalClass = cx([classes, backgroundColor]);

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => setChecked(!checked);

  return (
    <div className={classes.cardContainer}>
      <Card elevation={10} component={Paper} className={finalClass}>
        <CardHeader title={lesson.title} />
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="checked"
                  color="primary"
                />
              }
              label={`Level:${lesson.level}`}
            />
          </Typography>
          <Typography variant="body2" component="p">
            {lesson.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="large"
            href={lesson.link}
            target="_blank"
          >
            {lesson.callToAction || 'Learn More'}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
