import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { Lesson, ValidLessonTypes } from '../types';

import { LaunchPad } from './LaunchPad';
import { sortByDifficultyIncreasing } from '../utils/utils';
interface Props {
  name: ValidLessonTypes;
  lessons: Array<Lesson>;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    domainContainer: {},
    title: {}
  })
);

export const DomainContainer = ({ lessons, name }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid item sm={4}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h4" className={classes.title}>
            {name}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          {lessons
            .filter((l) => l.type === name)
            .sort((a, b) => sortByDifficultyIncreasing(a, b))
            .map((lesson, i) => (
              <LaunchPad key={i} lesson={lesson} />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
