import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme
} from '@material-ui/core';
import { DifficultyKey } from './components/DifficultyKey';
import { DomainContainer } from './components/DomainContainer';
import { lessons } from './data';
import { Lesson, ValidLessonTypes } from './types';
import './styles.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    treasureMap: {},
    theWay: {}
  })
);

export default function App(): JSX.Element {
  const classes = useStyles();
  const lessons: Array<Lesson> = [];
  return (
    <div className="App">
      <h1>Hello Adventurer!</h1>

      <p>
        I have long been wanting to make an "adventure" map out of the
        tutorials, articles, toys and tools that have been my own personal
        <a
          target="_blank"
          rel="noreferrer"
          href="https://lucid.app/lucidchart/84bad722-5cdf-442a-8a50-98e5039ad071/edit?invitationId=inv_6d8abbba-437d-4615-8aec-815f4705d4c6"
        >
          developer roadmap.
        </a>
        This is a LIVING document of what I felt were helpful things in learning
        to code.
      </p>
      <p>
        I've tried to organize the resources first by theme, and then by
        difficulty.
      </p>
      <DifficultyKey />
      <hr />
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          className={classes.theWay}
        >
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Pre-Work" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="HTML-CSS-Javascript" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Design" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Typescript/Node" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Linux" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Devops" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="General" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Python" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer
              name="Electron and Desktop Apps"
              lessons={lessons}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="React Native" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Security" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Crypto" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Other" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Educator/Influencer" lessons={lessons} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DomainContainer name="Linux" lessons={lessons} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
